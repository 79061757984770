import React from "react";

import { Link, graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import WeeNetwork from "assets/icons/Network-White.svg";
import WeeNetworkDark from "assets/icons/wee-network-dark.svg";
import WeeProviderMonitorDark from "assets/icons/wee-provider-monitor-dark.svg";
import NetworkFin from "assets/images/network-fin.svg";
import Divider from "components/Divider";
import Hero from "components/Hero";
import Next from "components/Next";
import PageContainer from "components/PageContainer";
import Section from "components/QantevSection";
import SEO from "components/SEO";
import WhatWeOffer from "components/WhatWeOffer";

const GridWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;

  @media (min-width: 600px) {
    grid-template-rows: 150px 180px 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-rows: 120px 140px 1fr;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
`;

const ListWrapper = styled.ul`
  li {
    color: #00033f;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 18px;
    list-style-type: none;
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

const LearnMoreWrapper = styled(Link)`
  margin-top: 1.5em;
  text-transform: uppercase;
  text-decoration: underline;
  color: #00033f;
`;

const NetworkManagementPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Hero
        title="Network Management"
        divider={<Divider icon={<img src={WeeNetwork} alt="wee-network" />} />}
        img={
          <Hero.HeroImage
            src={NetworkFin}
            alt="mesh-brain-connect"
            loading="lazy"
          />
        }
      >
        {t(
          "Qantev provides a modern tooling dedicated to health provider performance management, with an automated highlight of pricing gaps and best action recommendation based on advanced simulations."
        )}
      </Hero>
      <WhatWeOffer>
        <ListWrapper>
          <li>
            {t(
              "Automatically generates a comprehensive 360 view of payer's networks and providers"
            )}
          </li>
          <li>
            {t(
              "Real-time computation and tracking of highly specialized cost & quality KPIs"
            )}
          </li>
          <li>
            {t(
              "Automated identification of under performing providers and generation of negotiation business cases"
            )}
          </li>
          <li>
            {t(
              "Advanced benchmarking that dynamically ranks top condition and performs quantified comparison between similar providers thanks to a unique approach"
            )}
          </li>
          <li>
            {t(
              "Collaborative tooling with centralized knowledge base and action plans"
            )}
          </li>
        </ListWrapper>
      </WhatWeOffer>

      <Section>
        <Section.Left>
          <TextWrapper>
            <Divider
              icon={<img src={WeeProviderMonitorDark} alt="wee-provider" />}
              color="#00033f"
            />
            <GridWrapper>
              <h2 style={{ color: "#00033f", textTransform: "capitalize" }}>
                {t("Provider performance monitoring")}
              </h2>
              <p
                style={{
                  color: "#707070",
                  fontSize: "14px",
                  lineHeight: "23px",
                }}
              >
                {t(
                  "Qantev generates comprehensive 360 view of all the providers and computes 60+ highly specialised Cost & Quality KPIs in real time."
                )}
              </p>
              <LearnMoreWrapper to="/product/provider-monitoring">
                {t("Learn more")} &gt;
              </LearnMoreWrapper>
            </GridWrapper>
          </TextWrapper>
        </Section.Left>

        <Section.Right>
          <TextWrapper>
            <Divider
              icon={<img src={WeeNetworkDark} alt="wee-network" />}
              color="#00033f"
            />
            <GridWrapper>
              <h2 style={{ color: "#00033f", textTransform: "capitalize" }}>
                {t("Network Optimization")}
              </h2>
              <p
                style={{
                  color: "#707070",
                  fontSize: "14px",
                  lineHeight: "23px",
                }}
              >
                {t(
                  "Qantev identifies opportunities to save costs and increase efficiency. Our advanced tooling helps the user conduct quick analysis & comparisons, and easily build collaborative action plans and track their impact."
                )}
              </p>
              <LearnMoreWrapper to="/product/network-optimization">
                {t("Learn more")} &gt;
              </LearnMoreWrapper>
            </GridWrapper>
          </TextWrapper>
        </Section.Right>
      </Section>

      <Next
        text={
          <>
            Claims
            <br />
            Management
          </>
        }
        to="/product/claims-management"
      />
    </PageContainer>
  );
};

export default NetworkManagementPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Network Management" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["networkManagement", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
